import request from '@/utils/request'

// 查询短信日志列表
export function listLog(query) {
  return request({
    url: '/extra/sms-log/list',
    method: 'get',
    params: query
  })
}


// 统计短息发送数据
export function getSmsTotal(query) {
  return request({
    url: '/extra/sms-log/sms-total',
    method: 'get',
    params: query
  })
}


// 统计短息发送数据pieChart
export function getSmsTotalPieChart(query) {
  return request({
    url: '/extra/sms-log/sms-total-pie',
    method: 'get',
    params: query
  })
}

// 统计短息发送数据BarChart
export function getSmsTotalBarChart(query) {
  return request({
    url: '/extra/sms-log/sms-total-bar',
    method: 'get',
    params: query
  })
}
